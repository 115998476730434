export enum ESupportedLanguage {
  CHINESE = 'zh',
  ENGLISH = 'en',
  PORTUGUESE = 'pt',
  SPANISH = 'es',
}

export enum ESupportedRegionLanguage {
  CHINESE_SIMPLE = 'zh_CN',
  ENGLISH_US = 'en_US',
  PORTUGUESE_PORTUGAL = 'pt_PT',
  SPANISH_SPAIN = 'es_ES',
}
